import type { StoreType } from '../types'

import { useAlert } from '@shared/composable'
import { getServiceManageLanguagesStudents } from '@shared/api'
import { ServiceManageLanguagesStudentsGetRequest } from '@shared/swagger'
import { StatusMessageResponse } from '@/shared/types'

export default async function fetchStudents (this: StoreType, params: ServiceManageLanguagesStudentsGetRequest): Promise<StatusMessageResponse> {
  const { error } = useAlert()

  const { ok, message, data } = await getServiceManageLanguagesStudents(params)
  if (ok) {
    this.setStudents(data.students)
  } else {
    error({ text: message })
  }

  return {
    status: ok,
    message: message
  }
}
