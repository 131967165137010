<template>
  <vue-resizable
    :id="widgetId"
    class="card card-flush"
    :width="widgetSize.width"
    :height="widgetSize.height"
    :min-width="500"
    :min-height="500"
    @resize:end="(event) => onResize(event, widgetId)"
  >
    <div class="card-header mt-2">
      <h3 class="card-title align-items-start flex-column">
        <span
          v-test-id="'title'"
          class="card-label fw-bolder text-gray-800"
        >
          {{ $t('domains.dashboard.widgets.attendance.title') }}
        </span>
        <span
          v-test-id="'description'"
          class="text-gray-400 mt-1 fw-bold fs-6"
        >
          {{ $t('domains.dashboard.widgets.attendance.description') }}
        </span>
      </h3>
      <div class="card-toolbar">
        <a
          v-test-id="'view-all'"
          class="btn btn-sm btn-light"
          @click="goto('/academic/courses')"
        >
          {{ $t('domains.dashboard.widgets.attendance.view-all') }}
        </a>
      </div>
    </div>
    <div class="card-body mt-0 pt-2 pb-2W">
      <div class="row">
        <div class="col-12 col-md-5 col-lg-5">
          <p class="py-3">
            {{ $t('domains.dashboard.widgets.attendance.subjects-description') }}
          </p>
          <div class="hover-scroll-x h-275px">
            <div
              v-for="(subject, index) in comSubjects"
              :key="index"
              v-test-id="'a-items'"
              @click="showSubjectAttendance(subject)"
            >
              <a
                href="javascript:void(0)"
                class="d-flex flex-stack"
              >
                <div class="symbol symbol-40px me-4">
                  <div
                    class="symbol-label fs-8 fw-bold text-center"
                    :class="{
                      'bg-success text-inverse-success': comActive?.id === subject.id,
                      'bg-primary text-inverse-primary': comActive?.id !== subject.id
                    }"
                    v-html="replaceSpaceWithNewLine(subject.code)"
                  />
                </div>
                <div class="d-flex align-items-center flex-row-fluid flex-wrap">
                  <div class="flex-grow-1 me-2">
                    <a
                      class="text-gray-800 fs-6 fw-bolder"
                    >
                      {{ `${subject.title } [${subject.section}-${subject.section_type}]` }}
                    </a>
                    <span class="text-muted fw-bold d-block fs-7">
                      {{ `${formatWeekDay(subject.weekDay)} ${subject.startTime}` }}
                    </span>
                  </div>
                  <a
                    v-if="false"
                    href="javascript:void(0)"
                    :class="{
                      'btn-active-color-success': comActive?.id === subject.id,
                      'btn-active-color-primary': comActive?.id !== subject.id
                    }"
                    class="btn btn-sm btn-icon btn-bg-light w-30px h-30px"
                  >
                    <span
                      class="svg-icon svg-icon-2"
                      :class="{
                        'svg-icon-success': comActive?.id === subject.id
                      }"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                      >
                        <rect
                          opacity="0.5"
                          x="18"
                          y="13"
                          width="13"
                          height="2"
                          rx="1"
                          transform="rotate(-180 18 13)"
                          fill="currentColor"
                        />
                        <path
                          d="M15.4343 12.5657L11.25 16.75C10.8358 17.1642 10.8358 17.8358 11.25 18.25C11.6642 18.6642 12.3358 18.6642 12.75 18.25L18.2929 12.7071C18.6834 12.3166 18.6834 11.6834 18.2929 11.2929L12.75 5.75C12.3358 5.33579 11.6642 5.33579 11.25 5.75C10.8358 6.16421 10.8358 6.83579 11.25 7.25L15.4343 11.4343C15.7467 11.7467 15.7467 12.2533 15.4343 12.5657Z"
                          fill="currentColor"
                        />
                      </svg>
                    </span>
                  </a>
                </div>
              </a>
              <div
                v-if="index !== comSubjects.length - 1"
                class="separator separator-dashed my-4"
              />
            </div>
            <div
              v-if="comSubjects.length === 0"
              v-test-id="'no-data'"
              class="d-flex align-items-center justify-content-center min-h-150px"
            >
              {{ $t('domains.dashboard.widgets.moodle-info.no-data') }}
            </div>
          </div>
        </div>
        <div class="col-12 col-md-7 col-lg-7 d-flex flex-column align-items-center">
          <div class="d-flex fs-5 fw-bolder align-items-center">
            <span class="bg-primary symbol me-3 text-inverse-primary p-1">
              {{ `${comActive?.code}` }}
            </span>
            <span>
              {{ `${comActive?.title} [${comActive?.section}-${comActive?.section_type}]` }}
            </span>
          </div>
          <apexchart
            class="w-100"
            type="area"
            height="350"
            :options="comOptions"
            :series="comSeries"
          />
        </div>
      </div>
    </div>
  </vue-resizable>
</template>
<script lang="ts" setup>
import { computed, ref, watch, onMounted } from 'vue'
import { useRouter } from 'vue-router'
import { useI18n } from 'vue-i18n'
import { getCSSVariableValue } from '@/assets/ts/_utils'
import VueResizable from 'vue-resizable'
import { useDashboard } from '../store'
import { deviceType } from '@/shared/utils'
import { AttendanceChartOptions, AttendanceWidget, SeriesItem } from '../types'
import { onResize, getSavedWidgetSize } from '../utils'

const widgetId = 'attendance-widget'
const device = deviceType()

const widgetSize = ref({ width: device === 'mobile' ? '100%' : '45%', height: 'auto' })

const chartOptions: AttendanceChartOptions = {
  chart: {
    fontFamily: 'inherit',
    type: 'area',
    toolbar: {
      show: false
    }
  },
  plotOptions: {},
  legend: { show: false },
  dataLabels: { enabled: false },
  fill: {
    type: 'gradient',
    gradient: {
      shadeIntensity: 1,
      opacityFrom: 0.4,
      opacityTo: 0,
      stops: [0, 80, 100]
    }
  },
  stroke: {
    curve: 'smooth',
    show: true,
    width: 3,
    colors: [getCSSVariableValue('--bs-success'), getCSSVariableValue('--bs-danger'), getCSSVariableValue('--bs-info')]
  },
  xaxis: {
    categories: [],
    axisBorder: {
      show: false
    },
    axisTicks: {
      show: false
    },
    tickAmount: 6,
    labels: {
      rotate: 0,
      rotateAlways: true,
      style: {
        colors: getCSSVariableValue('--bs-primary'),
        fontSize: '12px'
      }
    },
    crosshairs: {
      position: 'front',
      stroke: {
        color: getCSSVariableValue('--bs-primary'),
        width: 1,
        dashArray: 3
      }
    },
    tooltip: {
      enabled: true,
      formatter: undefined,
      offsetY: 0,
      style: {
        fontSize: '12px'
      }
    }
  },
  yaxis: {
    title: { text: '' }
  },
  colors: [getCSSVariableValue('--bs-success'), getCSSVariableValue('--bs-danger'), getCSSVariableValue('--bs-info')]
}

const { t } = useI18n()
const router = useRouter()
const dashboard = useDashboard()

const attendance = computed<Array<AttendanceWidget>>(() => dashboard.getAttendances)
const comSubjects = ref<Array<AttendanceWidget>>(dashboard.getAttendances)
const comActive = ref<AttendanceWidget>()
const comSeries = ref<Array<SeriesItem>>([])
const comOptions = ref<AttendanceChartOptions>(chartOptions)

const replaceSpaceWithNewLine = (str: string) => str.replace(/\s/g, '<br>')
const showSubjectAttendance = (subject: AttendanceWidget) => {
  comActive.value = subject
  comSeries.value = subject.series
  comOptions.value.xaxis.categories = subject.xaxis.sort((a, b) => {
    const monthDiff = a.getMonth() - b.getMonth()
    if (monthDiff !== 0) {
      return monthDiff
    }
    return a.getDate() - b.getDate()
  }).map((date) => `${date.getDate()}.${date.getMonth() + 1}`)
}

comOptions.value.yaxis.title.text = t('domains.dashboard.widgets.attendance.student')

if (attendance.value.length > 0) {
  // eslint-disable-next-line vue/no-setup-props-destructure
  comActive.value = attendance.value[0]
  // eslint-disable-next-line vue/no-setup-props-destructure
  comOptions.value.xaxis.categories = attendance.value[0].xaxis.sort().map((date) => { return `${date.getDate}.${date.getMonth}` })
}

const formatWeekDay = (weekDay: number) => {
  const weekDays = ['sunday', 'monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday', 'sunday']
  return t('domains.dashboard.widgets.attendance.week-days.' + weekDays[weekDay])
}

const goto = (path:string, params = {}) => {
  router.push({ path: path, params })
}

comSubjects.value = comSubjects.value.sort((a, b) => {
  if (a.code === b.code) {
    if (a.weekDay === b.weekDay) {
      return a.startTime > b.startTime ? 1 : -1
    }
    return a.weekDay > b.weekDay ? 1 : -1
  }
  return a.code > b.code ? 1 : -1
})

watch(attendance, (newValue) => {
  comSubjects.value = newValue
  if (newValue.length > 0) {
    showSubjectAttendance(newValue[0])
  }
}, { immediate: true })

onMounted(() => {
  const savedSize = getSavedWidgetSize(widgetId)
  if (savedSize) {
    widgetSize.value.width = savedSize.width
    widgetSize.value.height = savedSize.height
  }
})
</script>
