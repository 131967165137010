import type {
  AcademicInformationSchedulesResourcesResponse
} from '@/shared/swagger'
import { SpecialityMapped } from '../types'

export default (specialities: AcademicInformationSchedulesResourcesResponse['specialities'], departments: AcademicInformationSchedulesResourcesResponse['departments']) => {
  return departments.map((department) => {
    return {
      title: department.title,
      value: department.id,
      code: department.code,
      selectable: false,
      specialities: specialities
        .filter((speciality) => speciality.department_id === department.id)
        .map((speciality) => specialityMapperFunction(speciality))
    }
  })
}

function specialityMapperFunction (speciality: AcademicInformationSchedulesResourcesResponse['specialities'][0]): SpecialityMapped {
  return {
    title: `${speciality.program_code} - ${speciality.title} (${speciality.language}) [${speciality.terms}]`,
    value: speciality.program_code,
    code: speciality.program_code,
    selectable: true
  }
}
