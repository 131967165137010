<template>
  <pms-card
    :title="'common.search'"
    :description="$t('domains.academic.all-students.search.description')"
    :options="{
      description: { class: 'mt-1 fs-7 text-muted' },
      body: { class: 'pt-0' },
    }"
  >
    <pms-loader v-if="loader" />
    <pms-smart-form
      v-else
      :fields="formFields"
      @handleSubmit="onSubmit"
      @handleChange="onHandleChange"
    >
      <template #formControls>
        <div class="mt-3 d-flex justify-content-end">
          <pms-button
            type="submit"
            class="mt-2"
            :disabled="loader"
            :loader="searchLoader"
            :text="$t('common.search')"
          />
        </div>
      </template>
      <template #speciality>
        <div class="card">
          <span class="mb-2 mt-1"> {{ t('common.search-field.select-speciality') }}:</span>
          <TreeSelect
            v-model="selectedSpeciality"
            :options="specialitiesMapped"
            placeholder=""
            class="p-1"
          />
        </div>
      </template>
    </pms-smart-form>
  </pms-card>
</template>
<script setup lang="ts">
import { useRoute } from 'vue-router'
import { computed, onMounted, watch, ref } from 'vue'
import { useI18n } from 'vue-i18n'
import TreeSelect from 'primevue/treeselect'
import { FormGroup } from '@shared/types'
import { useLoader, usePagination } from '@shared/composable'
import { useResource } from '@/shared/stores'

import { useAllStudents } from '../store'
import {
  formatterSpecialities,
  academicStatusToOption
} from '../utils'
import { resetAllStudentsSortField, tempValues } from '../values'

const { t } = useI18n()
const { isActive } = useLoader()
const { perPage } = usePagination()
const route = useRoute()
const store = useAllStudents()
const resource = useResource()
const selectedSpeciality = ref('')

const loader = isActive('academic/students/resources')
const searchLoader = isActive('academic/students')

const specialities = computed(
  () => resource.getAcademicStudentsResourceSpecialities
)
const academicStatuses = computed(
  () => resource.getAcademicStudentsResourceStatuses
)

const specialitiesMapped = computed(() =>
  formatterSpecialities(specialities.value)
)

const formFields = computed<Array<FormGroup>>(() => [
  {
    class: 'row',
    fields: [
      {
        label: {
          text: `${t('common.search-field.student-id')}:`,
          class: 'mb-1 mt-2'
        },
        group: {
          class: 'col-4'
        },
        name: 'student_id',
        type: 'text',
        value: tempValues.value.student_id || ''
      },
      {
        label: {
          text: `${t('common.search-field.name')}:`,
          class: 'mb-1 mt-2'
        },
        group: {
          class: 'col-4'
        },
        name: 'name',
        type: 'text',
        value: tempValues.value.name || ''
      },
      {
        label: {
          text: `${t('common.search-field.surname')}:`,
          class: 'mb-1 mt-2'
        },
        group: {
          class: 'col-4'
        },
        name: 'surname',
        type: 'text',
        value: tempValues.value.surname || ''
      },
      {
        name: 'speciality',
        type: 'slot',
        group: {
          class: 'col-4'
        }
      },
      {
        label: {
          text: `${t('common.search-field.select-academic-status')}:`,
          class: 'mb-1 mt-2'
        },
        group: {
          class: 'col-4'
        },
        name: 'status',
        type: 'select',
        value: tempValues.value.status || null,
        options: academicStatusToOption(academicStatuses.value)
      },
      {
        label: {
          text: `${t('common.search-field.class')}:`,
          class: 'mb-1 mt-2'
        },
        group: {
          class: 'col-4'
        },
        name: 'class',
        type: 'text',
        value: tempValues.value.class || ''
      },
      {
        label: {
          text: `${t('common.table.iin')}:`,
          class: 'mb-1 mt-2'
        },
        group: {
          class: 'col-12'
        },
        name: 'iin',
        type: 'text',
        value: tempValues.value.iin,
        validator: {
          schema: 'yup',
          rules: [
            'string',
            { func: 'label', value: t('common.table.iin') },
            { func: 'length', value: 12 }
          ]
        }
      }
    ]
  }
])

const onHandleChange = ({ name, newValue }) => {
  tempValues.value[name] = newValue
}

const onSubmit = async () => {
  resetAllStudentsSortField()
  await store.fetchStudents({ per_page: perPage.value, offset: 1 })
}

onMounted(() => {
  if (route.query.student_id) {
    tempValues.value.student_id =
      parseInt(route.query.student_id as string) ?? ''
  }
})

watch(selectedSpeciality, (newValue) => {
  const [key] = Object.entries(newValue)
  tempValues.value.speciality = Number(key[0])
})
</script>
