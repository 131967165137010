/* eslint-disable @typescript-eslint/no-explicit-any */
import { ref } from 'vue'
import { ServiceManageLanguagesStudentsGetResponse, ServiceManageLanguagesApplicantsGetResponse, ServiceManageLanguagesStudentsGetRequest, ServiceManageLanguagesApplicantsGetRequest } from '@/shared/swagger'

export interface ModalTableData{
    id: number,
    full_name: string
}

export const selectedStudents = ref<ServiceManageLanguagesStudentsGetResponse['students']>([])
export const selectedDatasForModal = ref<any>([])
export const selectedApplicants = ref<ServiceManageLanguagesApplicantsGetResponse['applicants']>([])
export const student_language_code = ([] as any)
export const applicant_language_code = ([] as any)
export const applicantsSearchValues = ref<ServiceManageLanguagesApplicantsGetRequest>({
  iin: undefined,
  language_code: '',
  department_code: '',
  entrance_year: new Date().getFullYear(),
  name: undefined,
  surname: undefined,
  no_level: undefined,
  per_page: 1,
  offset: 20
})
export const studentsSearchValues = ref<ServiceManageLanguagesStudentsGetRequest>({
  id: undefined,
  language_code: '',
  department_code: '',
  entrance_year: new Date().getFullYear(),
  name: undefined,
  surname: undefined,
  no_level: undefined,
  per_page: 1,
  offset: 20
})
