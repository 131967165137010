import type { StoreType } from '../types'

import { useAlert } from '@shared/composable'
import { getServiceManageLanguagesApplicants } from '@shared/api'
import { ServiceManageLanguagesApplicantsGetRequest } from '@shared/swagger'
import { StatusMessageResponse } from '@/shared/types'

export default async function fetchApplicants (this: StoreType, params: ServiceManageLanguagesApplicantsGetRequest): Promise<StatusMessageResponse> {
  const { error } = useAlert()

  const { ok, message, data } = await getServiceManageLanguagesApplicants(params)
  if (ok) {
    this.setApplicants(data.applicants)
  } else {
    error({ text: message })
  }

  return {
    status: ok,
    message: message
  }
}
