import { MappedChildren, SpecialityMapped } from '../types'

export default (data: SpecialityMapped[]): MappedChildren[] => {
  const options: MappedChildren[] = []
  const filterData = data.filter((el) => el.specialities && el.specialities.length > 0)

  filterData.forEach((el) => {
    const newObj = { key: el.code, label: el.title, children: [] as MappedChildren[] }
    if (el.specialities && el.specialities.length > 0) {
      newObj.children = el.specialities.map((spec) => ({
        key: spec.code,
        label: spec.title
      }))
    }
    options.push(newObj)
  })
  return options
}
