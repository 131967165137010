import { useUser } from '@/shared/stores'

import type { StoreType } from '../types'
import type { SignInRequest } from '@shared/swagger'
import { useAlert, useToken } from '@/shared/composable'
import { router } from '@/router'

export default async function authenticateUser (this: StoreType, credentials: SignInRequest) {
  const user = useUser()
  const token = useToken()
  const { error } = useAlert()
  const { ok, data, message } = await user.authUser(credentials)
  if (ok) {
    token.setTokenDetails({ ...data, roles: 'magnus' })
    token.setTokensEncrypted({ roles: data.roles, username: credentials.username, employee_id: data.employeeId })
    user.setAuthenticated(true)
    user.authMe()
    // временное решение
    // if (['SignInPage', 'SignOutPage'].includes(router.currentRoute.value.name as string)) {
    router.push({ name: 'DashboardPage' })
    // }
  } else {
    error({ text: message })
  }
}
